import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    ThunderClient: westack {
      tool(id: "thunder-client-for-vs-code") {
        ...ToolInfo
      }
    }
    LipGloss: westack {
      tool(id: "lip-gloss") {
        ...ToolInfo
      }
    }
    Glow: westack {
      tool(id: "terminal-glow") {
        ...ToolInfo
      }
    }
    BubbleTea: westack {
      tool(id: "bubble-tea") {
        ...ToolInfo
      }
    }
    CrawLab: westack {
      tool(id: "crawlab") {
        ...ToolInfo
      }
    }
    RSLint: westack {
      tool(id: "rslint") {
        ...ToolInfo
      }
    }
    VisuAlgo: westack {
      tool(id: "visualgo") {
        ...ToolInfo
      }
    }
    Blitz: westack {
      tool(id: "blitz-js") {
        ...ToolInfo
      }
    }
    ArchiveBox: westack {
      tool(id: "archivebox") {
        ...ToolInfo
      }
    }
    Rome: westack {
      tool(id: "rome") {
        ...ToolInfo
      }
    }
    Waypoint: westack {
      tool(id: "waypoint") {
        ...ToolInfo
      }
    }
    Zellij: westack {
      tool(id: "zellij") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.ThunderClient.tool} mdxType="Tool" />
    <Tool tool={props.data.LipGloss.tool} mdxType="Tool" />
    <Tool tool={props.data.Glow.tool} mdxType="Tool" />
    <Tool tool={props.data.CrawLab.tool} mdxType="Tool" />
    <Tool tool={props.data.RSLint.tool} mdxType="Tool" />
    <Tool tool={props.data.VisuAlgo.tool} mdxType="Tool" />
    <Tool tool={props.data.Blitz.tool} mdxType="Tool" />
    <Tool tool={props.data.ArchiveBox.tool} mdxType="Tool" />
    <Tool tool={props.data.Rome.tool} mdxType="Tool" />
    <Tool tool={props.data.Waypoint.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      